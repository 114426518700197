<template>
  <div class="content__sty">
    <div>
      <v-card
        class="mb-2"
        outlined
      >
        <div class="d-flex flex-no-wrap justify-space-between grey--text text--darken-3">
          <v-card-title class="body-1">{{ shopinfo.name }}</v-card-title>
          <v-chip
            small
            class="mt-3 mr-2 white--text fridge_title__stys"
            v-if="shopinfo.deliver_type === '1'"
          >
            {{$t('refrigeration_chip')}}
          </v-chip>
          <v-chip
            small
            class="mt-3 mr-2 white--text freezer_title__stys"
            v-else-if="shopinfo.deliver_type === '2'"
          >
            {{$t('freezing_chip')}}
          </v-chip>
          <v-chip
            small
            class="mt-3 mr-2 white--text normal_title__stys"
            v-else
          >
            {{$t('normal_chip')}}
          </v-chip>
        </div>
        <v-card-text>
          <v-row
            align="center"
            class="mx-0"
            style="margin-top: -25px;"
          >
          <div class="body-2">{{ shopinfo.spec }}</div>
          </v-row>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div class="my-4 headline red--text">
              NT${{ shopinfo.special_price }}
            </div>
            <div class="my-4 subtitle-1" style="text-decoration: line-through;">
              NT${{ shopinfo.price }}
            </div>
          </div>
          <!-- 選擇數量 -->
          <div>
            <span class="subheading">{{$t('ProductInfoShop_input1')}}</span>
            <div class="select" v-if="get_selectnum < 10">
              <select
                v-model="get_selectnum"
                @change="biggerten_onChange($event)"
              >
                <option
                  v-for="(option, idx) in canbuyarr"
                  :key="'B' + idx"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
              <div class="select_arrow"></div>
            </div>
            <div v-else>
              <v-text-field
                :value="get_selectnum"
                @change="v => get_selectnum = v"
                outlined
              ></v-text-field>
            </div>
            <span class="d-block text-right">
              <font v-if="canbuy >= 100">{{$t('ProductInfoShop_text1')}}</font>
              <font v-else>{{$t('ProductInfoShop_text2')}} {{ canbuy }} {{$t('ProductInfoShop_text3')}}</font>
            </span>
            <span class="d-block text-right" v-show="canbuy >= 100">
              <v-btn
                small
                icon
                @click="show_canbuy_num = !show_canbuy_num"
              >
                <v-icon color="grey lighten-1">mdi-alert-circle-outline</v-icon>
              </v-btn>
              <font class="text-right" v-show="show_canbuy_num">{{ canbuy }} {{$t('ProductInfoShop_text3')}}</font>
            </span>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mt-3 mb-5">
        <v-card-title>{{$t('ProductInfoShop_title1')}}</v-card-title>
        <v-card-text>
          <div class="shopinfo__html__sty" v-html="shopinfo.content"></div>
        </v-card-text>
      </v-card>
      <v-card-text class="white bottom__fixed__sty">
          <v-btn
            block
            depressed
            color="deep-orange lighten-1 white--text"
            v-if="not_add"
            @click="addCart"
          >
            <span class="white--text">{{$t('ProductInfoShop_button1')}}</span>
          </v-btn>
          <v-row v-else>
            <v-col class="pr-0">
              <v-btn
                block
                depressed
                color="white--text"
                class="home__btn__sty"
                @click="goHome"
              >
                <span class="white--text">{{$t('ProductInfoShop_button2')}}</span>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                depressed
                color="white--text"
                class="cart__btn__sty"
                @click="goCart"
              >
                <span class="white--text">{{$t('ProductInfoShop_button3')}}</span>
              </v-btn>
            </v-col>
          </v-row>
      </v-card-text>
    </div>
    <!-- login -->
    <div>
      <v-dialog
        v-model="login_dialog"
        width="500"
        persistent
        v-if="not_login"
      >
        <v-stepper v-model="login_info.el">
          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="mb-3">
                <v-card-title class="headline">{{$t('ProductInfoShop_title2')}}</v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="login_info.mobile"
                    type="number"
                    :label="$t('mobile_input')"
                  ></v-text-field>
                </v-card-text>
              </div>
              <v-btn
                color="primary"
                @click="checkUserState()"
              >
                {{$t('next_button')}}
              </v-btn>
              <v-btn text @click="dialogCancel()">{{$t('cancel_button')}}</v-btn>
            </v-stepper-content>
            <v-stepper-content step="2">
              <div class="mb-3">
                <v-card-title class="headline">{{$t('ProductInfoShop_title3')}}</v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="login_info.mobile"
                    type="number"
                    :label="$t('mobile_input')"
                  ></v-text-field>
                  <div class="text-center mb-5">
                    <v-btn
                      color="error"
                      @click="SendSMSCode()"
                      :disabled="sms_btn.smsbtndisable"
                    >
                      {{$t('ProductInfoShop_button4')}}
                      <span
                        class="pl-2"
                        v-show="sms_btn.smsbtndisable"
                      >
                        {{ sms_btn.smsCountDown }}{{$t('ProductInfoShop_text4')}}
                      </span>
                    </v-btn>
                  </div>
                  <v-text-field
                    v-model="login_info.sns"
                    :label="$t('ProductInfoShop_input2')"
                    outlined
                  ></v-text-field>
                </v-card-text>
              </div>
              <v-btn
                color="primary"
                @click="CheckSMSCode()"
              >
                {{$t('doubleCheck_button')}}
              </v-btn>
              <v-btn text @click="dialogCancel()">{{$t('cancel_button')}}</v-btn>
            </v-stepper-content>
            <v-stepper-content step="3">
              <div class="mb-3">
                <v-card-title class="headline">{{ set_password_tittle }}</v-card-title>
                <!-- <v-card-subtitle>需4位以上字母</v-card-subtitle> -->
                <v-card-text>
                  <v-text-field
                    :append-icon="show_area[0].password1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_area[0].password1 ? 'text' : 'password'"
                    @click:append="show_area[0].password1 = !show_area[0].password1"
                    v-model="login_info.newpassword"
                    :label="$t('password_input')"
                  ></v-text-field>
                  <v-text-field
                    :append-icon="show_area[0].password2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_area[0].password2 ? 'text' : 'password'"
                    @click:append="show_area[0].password2 = !show_area[0].password2"
                    v-model="login_info.checknewpassword"
                    :label="$t('doubleCheckPassword_input')"
                  ></v-text-field>
                </v-card-text>
              </div>
              <v-btn
                color="primary"
                @click="SendPassword()"
              >
                {{$t('sendPassword_button')}}
              </v-btn>
              <v-btn text @click="dialogCancel()">{{$t('cancel_button')}}</v-btn>
            </v-stepper-content>
            <v-stepper-content step="4">
              <div>
                <v-card-title class="headline">{{$t('ProductInfoShop_title4')}}</v-card-title>
                <v-card-text class="mb-n5">
                  <v-text-field
                    v-model="login_info.mobile"
                    :label="$t('mobile_input')"
                  ></v-text-field>
                  <v-text-field
                    :append-icon="show_area[0].password3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_area[0].password3 ? 'text' : 'password'"
                    @click:append="show_area[0].password3 = !show_area[0].password3"
                    v-model="login_info.password"
                    :label="$t('password_input')"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="ForgetPassword()">{{$t('forgetPassword_button')}}</v-btn>
                </v-card-actions>
              </div>
              <v-btn
                color="primary"
                @click="SendLogin()"
              >
                {{$t('send_button')}}
              </v-btn>
              <v-btn text @click="dialogCancel()">{{$t('cancel_button')}}</v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-dialog>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ProductInfoShop',
    props: ['uuid', 'shopinfo', 'canbuybiggerten', 'canbuyarr', 'selectnum', 'canbuy'],

    data() {
      return {
        biggertencontent: '',
        get_selectnum: parseInt(this.selectnum),
        get_bigger_selectnum: 10,
        not_add: true,
        get_store_cart: [],
        show_canbuy_num: false,
        get_canbuy: parseInt(this.canbuy),
        // login
        login_info: {
          el: 1,
          mobile: '',
          sns: '',
          newpassword: '',
          checknewpassword: '',
          token: '',
          user_uuid: '',
          password: '',
          cheer_point: null,
        },
        login_dialog: false,
        not_login: true,
        show_area: [{
          password1: false,
          password2: false,
          password3: false,
        }],
        // 簡訊按鈕倒數
        sms_btn: {
          smsCountDown: 180,
          smsbtndisable: false,
        },
        // 設定密碼文字
        set_password_tittle: '請設定密碼,作為下次登入使用',
        // 加入購物車
        push_store_cart: []
      };
    },
    computed: {
      biggertenmessage : {
        get() {
          return this.biggertencontent
        }, set(v) {
          this.biggertencontent=v
        }
      }
    },
    created() {
    },
    methods: {
      biggerten_onChange(event) {
        if (event.target.value === '+10') {
          this.get_selectnum = 10;
        }
        // console.log(event.target.value);
      },
      getStoreCart() {
        // cart
        if (this.$store.state.cartlist.cartData !== undefined) {
          for (let i = 0; i < this.$store.state.cartlist.cartData.length; i++) {
            this.get_store_cart.push(this.$store.state.cartlist.cartData[i]);
          }
        } else {
          this.get_store_cart = [];
        }
      },
      // 重複商品處理
      pushCart() {
        for (let i = 0; i < this.push_store_cart.length; i++) {
          if (this.push_store_cart[i].production_id === this.uuid) {
            this.push_store_cart[i].qty += parseInt(this.get_bigger_selectnum);
            return;
          }
        }
        this.push_store_cart.push(
          { 
            production_id : this.uuid,
            qty: parseInt(this.get_bigger_selectnum),
          }
        );
      },
      // 加入購物車
      addCart() {
        if (this.get_selectnum > this.canbuy) {
          alert('庫存不足');
          return false;
        } else {
          if (this.$store.state.putcartlist.putcartData !== undefined) {
            this.push_store_cart = this.$store.state.putcartlist.putcartData;
          }
          if (this.get_selectnum !== '+10') {
            this.get_bigger_selectnum = this.get_selectnum;
            this.pushCart();
          } else {
            this.pushCart();
          }
          // store vuex
          this.$store.commit({
            type: 'setPutCartData',
            putcartData: this.push_store_cart,
          });
          // 查詢商品運費/免運門檻/付款方式
          this.getProductInfo();
          this.not_add = false;
        }
      },
      // 取得商品運費/免運門檻/付款方式
      getProductInfo() {
        this.axios({
          method: 'post',
          url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/productions/product-paymethod`,
          data: {
            access_key: process.env.VUE_APP_ACCESSKEY,
            production_id: this.uuid,
          },
        })
          .then((response) => {
            // 儲存付款方式
            // 信用卡: 1, Line Pay: 3, 先取貨後支付 AFTEE: 4, 銀行轉帳: 6
            let pay_type = [];
            pay_type.push(
              { pay_method: this.$t('credit_card'), is_support: response.data.is_credit, pay_id: 1 },
              { pay_method: 'Line Pay', is_support: response.data.is_line, pay_id: 3 },
              { pay_method: this.$t('aftee'), is_support: response.data.is_aftee, pay_id: 4 },
              { pay_method: this.$t('atm'), is_support: response.data.is_atm, pay_id: 6 }
            );
            // store vuex
            this.$store.commit({
              type: 'setPaymentData',
              paymentData: pay_type,
            });
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      },
      // get cookie
      readCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
          let c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
      },
      // 返回購物車選項
      goHome() {
        this.$router.push(`/?store=${this.shopinfo.store_uuid}`);
      },
      goCart() {
        // 已登入
        if (
          this.readCookie('accessToken') !== '' &&
          this.readCookie('accessToken') !== null &&
          this.readCookie('user_uuid') !== '' &&
          this.readCookie('user_uuid') !== null
        ) {
          sessionStorage.setItem('accessToken', this.readCookie('accessToken'));
          sessionStorage.setItem('user_uuid', this.readCookie('user_uuid'));
          // check token 有沒有過期
          const token = this.readCookie('accessToken');
          const uuid = this.readCookie('user_uuid');
          this.axios({
            method: 'post',
            url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/users/user-info`,
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: {
                user_uuid: uuid
            },
          })
          .then((response) => {
            if (response.status === 200) {
              this.login_dialog = false;
              this.$router.push(`/cart`);
            }
          })
          .catch((error) => {
              alert(error.response.data.message);
              if (error.response.data.message === 'token過期') {
                  sessionStorage.setItem('accessToken', '');
                  sessionStorage.setItem('user_uuid', '');
                  document.cookie = 'accessToken' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                  document.cookie = 'user_uuid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                  this.login_dialog = true;
              }
          });
        } else {
          //  判斷是不是從app進入
          if (navigator.userAgent.indexOf('cheerlife') !== -1) {
            const geturl = window.location.search;
            if (geturl.indexOf('phone') >= 0) {
              this.login_info.mobile = this.$route.query.phone;
              // login axios
              this.axios({
                method: 'post',
                url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/users/get-user-token`,
                data: {
                  access_key: process.env.VUE_APP_ACCESSKEY,
                  mobile: this.login_info.mobile,
                },
              })
                .then((response) => {
                  // console.log(response);
                  this.login_info.token = response.data.token;
                  this.login_info.user_uuid = response.data.user_uuid;
                  sessionStorage.setItem('accessToken', this.login_info.token);
                  sessionStorage.setItem('user_uuid', this.login_info.user_uuid);
                  sessionStorage.setItem('mobile', this.login_info.mobile);
                  // set cookies
                  const expire_day = 1;
                  const get_day = new Date();
                  get_day.setTime(get_day.getTime() + (expire_day * 24 * 60 * 60 * 1000));
                  const expires = `expires=${get_day.toGMTString()}`;
                  document.cookie = `accessToken=${this.login_info.token}; expires=${expires};`;
                  document.cookie = `user_uuid=${this.login_info.user_uuid}; expires=${expires};`;
                  document.cookie = `mobile=${this.mobile}; expires=${expires};`;
                  this.$router.push('/cart');
                })
                .catch((error) => {
                  alert(error.response.data.message);
                });
            } else {
              // alert('錯誤！！取不到手機號碼');
              this.login_dialog = true;
            }
          } else {
            this.login_dialog = true;
          }
        }
      },
      // login
      checkUserState() {
        this.axios({
          method: 'post',
          url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/users/check-user-register`,
          data: {
            access_key: process.env.VUE_APP_ACCESSKEY,
            mobile: this.login_info.mobile,
          },
        })
          .then((response) => {
            this.login_info.user_uuid = response.data.user_uuid;
            if (response.data.message === 'registered') {
              this.login_info.el = 4;
            } else {
              this.SMSCountDownTimer();
              this.login_info.el = 2;
            }
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      },
      dialogCancel() {
        this.login_dialog = false;
        this.login_info.el = 1;
      },
      SMSCountDownTimer() {
        if (this.sms_btn.smsCountDown > 0) {
          this.sms_btn.smsbtndisable = true;
          setTimeout(() => {
            this.sms_btn.smsCountDown -= 1
            this.SMSCountDownTimer();
          }, 1000)
        } else {
          this.sms_btn.smsbtndisable = false;
        }
      },
      SendSMSCode() {
        // 發送驗證碼
        this.axios({
          method: 'post',
          url: `https://${process.env.VUE_APP_LINEPAYAPI}.cheerlife.io/cheerlife/v1/web-users/send-verify`,
          data: {
            access_key: process.env.VUE_APP_LINEPAYAPIACCESSKEY,
            mobile: this.login_info.mobile,
            user_uuid: this.login_info.user_uuid,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              alert('已發送！！');
              this.sms_btn.smsCountDown = 180;
              this.SMSCountDownTimer();
            } else {
              alert(response.data.message);
              return false;
            }
          })
          .catch((error) => {
            alert(`status code: ${ error.response.status }`);
            alert(error.response.data.message);
          });
      },
      CheckSMSCode() {
        // 確認驗證碼
        this.axios({
          method: 'post',
          url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/users/check-verify-code`,
          data: {
            access_key: process.env.VUE_APP_ACCESSKEY,
            mobile: this.login_info.mobile,
            code: this.login_info.sns
          },
        })
          .then((response) => {
            if (response.data.message === 'success') {
              this.login_info.token = response.data.token;
              this.login_info.user_uuid = response.data.user_uuid;
              this.login_info.el = 3;
            } else {
              alert(response.data.message);
            }
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      },
      SendPassword() {
        // 設定密碼
        if (this.login_info.newpassword !== this.login_info.checknewpassword) {
          alert('密碼需一致');
        } else if (this.login_info.newpassword === '' || this.login_info.checknewpassword === '') {
          alert('密碼不可空值');
        } else {
          const userpassword = this.Base64.encode(this.login_info.newpassword);
          this.axios({
          method: 'post',
          url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/users/set-password`,
          headers: {
            Authorization: `Bearer ${this.login_info.token}`
          },
          data: {
            user_uuid: this.login_info.user_uuid,
            password: userpassword
          },
        })
          .then((response) => {
            if (response.data.message === 'success') {
              alert('密碼設定成功！！');
              this.login_info.el = 4;
            }
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
        }
      },
      ForgetPassword() {
        this.set_password_tittle = this.$t('ProductInfoShop_title5');
        this.login_info.el = 2;
      },
      SendLogin() {
        const userpassword = this.Base64.encode(`${this.login_info.mobile}:${this.login_info.password}`);
        // 登入
        this.axios({
          method: 'get',
          url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/users/login`,
          headers: {
            Authorization: `Basic ${userpassword}`
          }
        })
          .then((response) => {
            this.login_info.token = response.data.token;
            this.login_info.user_uuid = response.data.user_uuid;
            this.login_info.cheer_point = response.data.cheer_point;
            // set sessionstorage
            sessionStorage.setItem('accessToken', this.login_info.token);
            sessionStorage.setItem('user_uuid', this.login_info.user_uuid);
            // set cookies
            const expire_day = 1;
            const get_day = new Date();
            get_day.setTime(get_day.getTime() + (expire_day * 24 * 60 * 60 * 1000));
            const expires = `expires=${get_day.toGMTString()}`;
            document.cookie = `accessToken=${this.login_info.token}; expires=${expires};`;
            document.cookie = `user_uuid=${this.login_info.user_uuid}; expires=${expires};`;
            this.$router.push(`/cart`);
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      }
    },
    mounted() {
      this.getStoreCart();
    },
    beforeDestroy() {
    },

  }
</script>

<style lang="sass">
  .shopinfo__html__sty img
    max-width: 100%
    height: auto
    display: block
    margin-left: auto
    margin-right: auto
</style>
<style scoped lang="sass">
  .select
    position: relative
    display: inline-block
    margin-bottom: 15px
    width: 100%
  .select select
    display: inline-block
    width: 100%
    cursor: pointer
    padding: 10px 15px
    outline: 0
    border: 0
    border-radius: 0
    background: #f8f8f8
    color: #7b7b7b
    appearance: none
    -webkit-appearance: none
    -moz-appearance: none
  .select select::-ms-expand
    display: none
  .select select:hover,
  .select select:focus
    color: #000
    background: #ccc
  .select select:disabled
    opacity: 0.5
    pointer-events: none
  .select_arrow
    position: absolute
    top: 16px
    right: 15px
    width: 0
    height: 0
    pointer-events: none
    border-style: solid
    border-width: 8px 5px 0 5px
    border-color: #7b7b7b transparent transparent transparent
  .select select:hover ~ .select_arrow,
  .select select:focus ~ .select_arrow
    border-top-color: #000
  .select select:disabled ~ .select_arrow
    border-top-color: #ccc
  .v-application .home__btn__sty
    font-weight: normal
    font-size: 15px
    background-color: #352D40 !important
    border-color: #352D40 !important
  .v-application .cart__btn__sty
    font-weight: normal
    font-size: 15px
    background-color: #FF781E !important
    border-color: #FF781E !important
  .theme--light.v-chip:not(.v-chip--active).normal_title__stys, .normal_title__stys
    background-color: #ED7978
  .theme--light.v-chip:not(.v-chip--active).fridge_title__stys, .fridge_title__stys
    background-color: #3AAEA5
  .theme--light.v-chip:not(.v-chip--active).freezer_title__stys, .freezer_title__stys
    background-color: #1A78C9
  .bottom__fixed__sty
    position: fixed
    bottom: 0%
    box-shadow: 0 0 8px 0px #b1b1b1
  .content__sty
    margin-bottom: 64px
</style>
